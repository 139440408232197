
import { authMapGetters } from "@/store/modules/auth";
import Vue from "vue";
// import PageWrapDefault from "../wrappers/PageWrapDefault.vue";
import EditAgency from "../admin/agencies/EditAgency.vue";

export default Vue.extend({
  name: "agencyAdminEditAgency",
  components: { EditAgency },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    currentUser() {
      return this.getCurrentUser;
    }
  }
});
